.buy-more-btn {
  width: 280px;
  height: fit-content;
  padding: 24px;
  font-weight: 800;
  font-size: 20px;
  line-height: 115%;
  background-color: $purpure-color;
  border-radius: 100px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 30px $purpure-color;
  z-index: 2;
  color: $white;

  &:hover {
    background-color: $purpure-color-hover;
    box-shadow: 0px 0px 40px $purpure-color-hover;
  }
}
.buy-more-btn-2 {
  width: 280px;
  height: fit-content;
  padding: 24px;
  margin-top: 70px;
  font-weight: 800;
  font-size: 20px;
  line-height: 115%;
  background-color: $purpure-color;
  border-radius: 100px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 30px $purpure-color;
  z-index: 2;
  color: $white;

  &:hover {
    background-color: $purpure-color-hover;
    box-shadow: 0px 0px 40px $purpure-color-hover;
  }
}