// Colors HEX
$white: #ffffff;

$light-color: #fdf7fa;
$common-color: #262626;
$secondary-color: #1f1f1f;
$purpure-color: #8c60bb;
$green-color: #4b8f8c;
$blue-color: #6b6ef9;

$purpure-color-hover: #b26ffa;
$blue-color-hover: #8181f5;

// font
$common-font: 'Montserrat', sans-serif;

// size
$block-width: 992px; ;
