.nft-item {
  width: 144px;
  position: relative;

  @include for-phone {
    position: unset;
  }

  &__img {
    width: 144px;
    height: auto;
    border-radius: 5px;
  }

  &:hover {
    cursor: pointer;

    .nft-item__info {
      opacity: 1;
      pointer-events: unset;
    }
  }

  &__footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  &__info {
    transition: all 0.3s ease-in-out;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 20;

    @include for-phone {
      margin-top: -150px;
      top: unset;
      left: 5%;
      width: 90%;
    }
  }
}
