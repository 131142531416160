.social-links {
  display: flex;
  gap: 20px;

  &__item {
    transition: all 0.2s ease-in-out;
    width: 24px;
    height: 24px;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}
